import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './reset.css'
import './index.css';
import App from './App';
import {configureStore} from './store';
import csrfFetch, { restoreCSRF } from './store/csrf';
import * as sessionActions from './store/session';
import { ModalProvider } from './context/Modal';
import { ShippingModalProvider } from './context/ShippingModal';

const store = configureStore();

if (process.env.NODE_ENV !== 'production') {
  window.store = store;
  window.csrfFetch = csrfFetch;
  window.sessionActions = sessionActions;
}

function Root() {
  return (
    <ModalProvider>
      <ShippingModalProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ShippingModalProvider>
    </ModalProvider>
  )
}

const renderApplication = () => {
  ReactDOM.render(
      <React.StrictMode>
        <Root />
      </React.StrictMode>,
    document.getElementById('root')
  );
}

if (
  sessionStorage.getItem("currentUser") === null ||
  sessionStorage.getItem("X-CSRF-Token") === null
  ) {
    store.dispatch(sessionActions.restoreSession()).then(renderApplication);
} else {
  renderApplication();
}
