export const cartImage = <svg xlmns="http://www.w3.org/2000/svg" width="22" height="17" viewvox="0 0 22 17" fill="none">
    <path d="">

    </path>
    <path d="M15.5846 2.75C15.9988 2.75 16.3346 2.41421 16.3346 2C16.3346 1.58579 15.9988
    1.25 15.5846 1.25V2.75ZM3.67285 1.25C3.25864 1.25 2.92285 1.58579 2.92285 2C2.92285
    2.41421 3.25864 2.75 3.67285 2.75V1.25ZM15.5846 1.25H3.67285V2.75H15.5846V1.25Z" fill="black">
    </path>

    <path d="M4.6875 9.05882C4.6875 9.47304 5.02329 9.80882 5.4375 9.80882C5.85171
    9.80882 6.1875 9.47304 6.1875 9.05882L4.6875 9.05882ZM6.1875 2C6.1875 1.58579
    5.85171 1.25 5.4375 1.25C5.02329 1.25 4.6875 1.58579 4.6875 2L6.1875 2ZM6.1875
    9.05882L6.1875 2L4.6875 2L4.6875 9.05882L6.1875 9.05882Z" fill="black">
    </path>

    <path d="M14.0497 1.66459C13.8645 1.29411 13.414 1.14394 13.0435 1.32918C12.673
    1.51442 12.5228 1.96493 12.7081 2.33541L14.0497 1.66459ZM17.5791 8.72341L14.0497
    1.66459L12.7081 2.33541L16.2375 9.39423L17.5791 8.72341Z" fill="black">
    </path>

    <path d="M1.02578 14.353H19.9964V11.2647C19.9964 10.2059 17.9376 9.05885 16.9082
    8.61768L15.1435 11.7059H10.2906V9.94121C10.2906 9.67775 6.93755 9.05885 4.11402
    9.05885C1.29049 9.05885 0.878722 12.5883 1.02578 14.353Z" stroke="black" stroke-width="1.5" stroke-linejoin="round">
    </path>

    <path d="M13.4679 6.05963C13.7608 5.76673 13.7608 5.29186 13.4679 4.99897C13.175
    4.70607 12.7001 4.70607 12.4072 4.99897L13.4679 6.05963ZM10.6425 6.76367C10.3496
    7.05657 10.3496 7.53144 10.6425 7.82433C10.9354 8.11723 11.4103 8.11723 11.7032
    7.82433L10.6425 6.76367ZM16.5561 9.85191L12.5855 5.88132L11.5249 6.94198L15.4955
    10.9126L16.5561 9.85191ZM12.4072 4.99897L10.6425 6.76367L11.7032 7.82433L13.4679
    6.05963L12.4072 4.99897Z" fill="black">
    </path>
    <circle cx="4.55526" cy="14.3529" r="1.89706" fill="white" stroke="black" stroke-width="1.5" />
    <circle cx="15.55526" cy="14.3529" r="1.89706" fill="white" stroke="black" stroke-width="1.5" />
</svg>
