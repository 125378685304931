import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import * as sessionActions from '../../store/session';
import './ProfileButton.css'
import { useHistory } from "react-router-dom";

function ProfileButton({ user }) {
  const history = useHistory()
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);

  const openMenu = () => {
    if (showMenu) return;
    setShowMenu(true);
  };

  useEffect(() => {
    if (!showMenu) return;

    const closeMenu = () => {
      setShowMenu(false);
    };

    document.addEventListener('click', closeMenu);

    return () => document.removeEventListener("click", closeMenu);
  }, [showMenu]);

  const logout = (e) => {
    e.preventDefault();
    dispatch(sessionActions.logout());
  };

  // const profile = (e) => {
  //   e.preventDefault();
  //   history.push(`/users/${user}/profile`)
  // }

  return (
    <div className="profile-container">
      <button className="profile-button" onClick={openMenu}>
        MyAccount
      </button>
      <br />
      {showMenu && (
        <ul className="profile-dropdown">
          <li>{user.username}</li>
          {/* <li>
            <button className="user-profile-link-button" onClick={profile}>Profile</button>
          </li> */}
          <li>{user.email}</li>
          <li>
            <button className="logout-button" onClick={logout}>Log Out</button>
          </li>
        </ul>
      )}
    </div>
  );
}

export default ProfileButton;
