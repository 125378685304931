// https://www.myfonts.com/pages/whatthefont create a ballaway logo


export const Logo = <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="70.000000pt" height="70.000000pt" viewBox="0 0 300.000000 173.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.10, written by Peter Selinger 2001-2011
</metadata>
<g transform="translate(0.000000,173.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1171 1681 c-57 -57 -59 -81 -22 -314 28 -183 30 -203 16 -224 -12
-18 -13 -24 -3 -27 7 -2 33 -9 58 -16 25 -7 52 -19 62 -27 23 -20 30 -2 19 49
-6 24 -28 152 -51 286 -37 224 -39 245 -25 272 19 36 19 40 0 40 -8 0 -33 -18
-54 -39z m45 -315 c20 -119 34 -220 31 -223 -12 -11 -25 9 -31 45 -3 20 -17
113 -31 206 -23 147 -26 210 -10 194 2 -2 21 -102 41 -222z"/>
<path d="M908 1643 c-63 -66 -62 -105 7 -331 56 -185 63 -216 45 -227 -19 -12
1 -25 37 -25 19 0 50 -7 69 -16 19 -9 38 -14 41 -10 4 3 -33 131 -81 283 -83
260 -89 292 -71 336 11 27 -17 21 -47 -10z m71 -315 c35 -112 64 -207 65 -212
2 -14 -24 -19 -28 -4 -34 107 -116 393 -116 404 0 46 23 -9 79 -188z"/>
<path d="M1780 1566 c0 -4 5 -15 12 -25 17 -28 -24 -346 -48 -372 -20 -22 -20
-21 26 -44 19 -9 50 -31 67 -48 l33 -31 37 18 37 17 58 -40 c51 -35 62 -39 80
-30 12 7 44 14 71 18 l49 6 50 140 c53 150 58 206 24 244 -16 18 -20 18 -57 3
-40 -15 -48 -29 -27 -50 9 -9 1 -44 -33 -152 -24 -77 -46 -140 -49 -140 -3 0
-20 13 -37 28 l-33 27 30 110 c37 136 38 175 6 212 l-25 28 -35 -19 c-35 -19
-46 -39 -26 -51 6 -3 10 -14 10 -23 0 -37 -64 -262 -74 -262 -6 0 -24 10 -40
23 l-28 23 25 159 c27 176 25 189 -26 210 -48 19 -77 27 -77 21z m59 -231
c-17 -107 -20 -144 -12 -166 11 -29 8 -35 -14 -21 -10 6 -8 43 7 167 11 87 20
163 20 169 0 6 4 6 11 -1 8 -8 5 -49 -12 -148z m221 52 c0 -12 -11 -65 -25
-117 -31 -120 -31 -141 1 -179 31 -38 26 -39 -27 -5 -31 19 -39 29 -33 42 17
39 64 230 64 260 0 20 4 31 10 27 6 -3 10 -16 10 -28z m200 -45 c0 -24 -77
-243 -90 -256 -16 -16 -12 3 23 110 19 54 37 116 41 137 7 36 26 43 26 9z"/>
<path d="M1455 1540 c-16 -11 -38 -20 -47 -20 -43 0 -22 -21 43 -42 76 -24 89
-39 89 -99 0 -21 -4 -29 -12 -25 -7 2 -30 10 -51 17 -37 12 -39 11 -63 -17
-44 -51 -77 -132 -71 -172 6 -34 59 -102 81 -102 5 0 31 18 57 40 26 22 51 40
56 40 4 0 15 -15 24 -32 26 -54 42 -57 91 -13 53 48 56 55 25 55 -23 0 -25 4
-30 68 -4 37 -7 105 -7 152 -1 55 -5 91 -14 103 -10 12 -127 68 -140 67 -1 0
-14 -9 -31 -20z m134 -77 c5 -10 11 -76 14 -148 3 -71 8 -140 12 -152 5 -16 3
-23 -6 -23 -26 0 -40 38 -34 89 3 27 1 61 -5 76 -7 20 -7 37 2 61 9 27 9 40
-4 73 -14 34 -14 41 -3 41 8 0 18 -8 24 -17z m-71 -144 c19 -7 22 -16 22 -60
0 -40 -5 -55 -22 -70 -32 -26 -46 -24 -68 14 -22 35 -26 87 -9 118 10 20 17
20 77 -2z m-78 -171 c0 -12 -25 -9 -38 4 -7 7 -12 23 -12 38 l0 25 25 -30 c13
-16 24 -33 25 -37z"/>
<path d="M642 1373 c-48 -4 -58 -9 -93 -45 -21 -23 -39 -43 -39 -46 0 -2 36
-1 79 3 l80 7 20 -41 c27 -52 26 -59 -4 -53 -53 10 -73 -4 -91 -65 -33 -115 3
-206 88 -219 34 -6 36 -5 67 50 18 31 35 56 37 56 3 0 20 -11 38 -25 18 -14
38 -25 44 -25 16 0 73 103 62 114 -6 6 -15 4 -23 -3 -20 -16 -25 -8 -92 146
-50 116 -61 133 -88 142 -16 6 -30 10 -31 9 -1 -1 -25 -3 -54 -5z m95 -73 c45
-87 123 -263 119 -267 -12 -13 -45 21 -61 64 -10 26 -23 55 -30 63 -7 8 -15
31 -19 51 -4 21 -18 53 -31 73 -30 44 -30 49 -8 44 10 -2 23 -14 30 -28z m11
-187 c18 -43 19 -50 6 -75 -19 -37 -39 -35 -80 6 -28 28 -34 42 -34 75 l0 41
44 0 c42 0 44 -1 64 -47z m-58 -144 c0 -5 -5 -9 -12 -9 -17 0 -48 31 -48 47 0
11 8 9 30 -7 17 -12 30 -26 30 -31z"/>
{/* below is the C path */}
<path d="M358 1320 c-9 -6 -51 -17 -94 -25 -90 -17 -141 -47 -185 -108 -50
-69 -62 -105 -63 -192 -1 -103 21 -169 79 -233 60 -67 126 -95 226 -95 125 -1
188 42 260 176 29 55 30 60 14 76 -16 15 -19 13 -48 -23 -81 -100 -136 -136
-210 -136 -34 0 -60 27 -52 54 3 12 8 44 11 71 5 45 1 60 -40 143 -71 144 -60
180 59 190 75 6 95 23 95 79 0 35 -18 43 -52 23z m-168 -109 c-14 -27 -27 -34
-36 -20 -4 7 1 18 11 26 27 19 37 16 25 -6z m-36 -67 c3 -9 1 -34 -5 -56 -10
-38 -7 -46 31 -127 55 -112 62 -137 44 -151 -10 -9 -21 -7 -47 9 -91 56 -128
195 -77 291 27 52 43 62 54 34z m66 -136 c19 -31 28 -69 13 -55 -9 10 -43 87
-37 87 2 0 13 -15 24 -32z m-69 -219 c21 -15 40 -20 62 -16 26 4 39 -2 70 -29
l37 -34 -27 0 c-53 0 -106 22 -147 61 -45 42 -42 52 5 18z"/>
<path d="M2440 1302 c0 -4 23 -30 52 -59 l51 -52 -13 -41 c-18 -55 -28 -58
-57 -21 -13 17 -33 31 -44 31 -62 0 -164 -80 -175 -137 -8 -41 10 -109 30
-116 8 -3 38 1 68 9 29 7 56 14 59 14 3 0 9 -23 12 -51 4 -29 8 -53 10 -55 8
-8 121 23 125 34 2 7 0 12 -5 12 -4 0 -14 4 -21 9 -11 6 1 38 52 139 36 73 66
143 66 156 0 15 -18 44 -47 76 -46 49 -51 51 -105 54 -32 2 -58 1 -58 -2z
m160 -137 c0 -13 -22 -68 -49 -123 -26 -55 -53 -114 -59 -131 -6 -18 -16 -30
-22 -26 -17 10 -11 55 10 85 11 16 27 46 34 67 8 21 25 58 40 83 14 25 26 53
26 63 0 10 5 15 10 12 6 -3 10 -17 10 -30z m-145 -75 l30 -31 -25 -46 c-26
-50 -75 -76 -92 -50 -18 28 -8 95 18 126 14 17 29 31 33 31 4 0 20 -14 36 -30z
m-135 -90 c0 -22 -4 -40 -10 -40 -16 0 -23 49 -10 65 18 22 20 19 20 -25z"/>
<path d="M2743 1093 c-35 -4 -44 -20 -18 -29 22 -9 20 -15 -34 -101 -78 -126
-91 -157 -91 -215 0 -65 -11 -86 -30 -63 -21 25 -69 47 -89 40 -23 -7 -58 -45
-42 -45 6 0 31 -18 55 -40 57 -52 86 -53 138 -1 34 35 43 39 73 34 45 -7 110
25 179 88 59 54 92 118 98 189 l3 45 -44 0 -43 0 -17 -67 c-18 -71 -45 -109
-95 -135 -45 -23 -85 -33 -92 -22 -3 5 28 60 68 122 88 132 90 137 77 177 -11
31 -16 33 -96 23z m57 -62 c0 -8 -60 -103 -71 -111 -13 -10 -69 -117 -69 -132
0 -8 9 -27 20 -41 l20 -25 -25 -4 c-31 -6 -35 -2 -35 34 0 33 17 71 78 175 23
39 46 83 52 98 9 23 30 27 30 6z"/>
<path d="M1062 718 c-7 -7 -12 -23 -12 -36 0 -19 304 -511 389 -629 36 -49 74
-49 117 -1 49 55 394 608 394 631 0 31 -23 51 -52 43 -13 -3 -101 -91 -197
-196 -143 -156 -179 -190 -201 -190 -22 0 -55 30 -176 161 -82 89 -163 177
-180 195 -33 35 -61 43 -82 22z m134 -135 c43 -48 122 -132 174 -185 140 -144
126 -148 370 117 133 144 171 178 156 140 -11 -29 -356 -562 -374 -579 -11 -9
-26 -14 -33 -11 -14 5 -371 551 -385 588 -13 36 15 14 92 -70z"/>
</g>
</svg>
